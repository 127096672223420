import React, { Suspense } from "react"
// import FLSWork from "../components/HomePage/FLSWork/FLSWork"
// import Hero from "../components/HomePage/Hero/Hero"
// import NewWork from "../components/HomePage/NewWork/NewWork"
// import RatingText from "../components/HomePage/RatingText/RatingText"
// import BlogCards from "../components/LatestBlogs/LatestBlogs"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
const FLSWork = React.lazy(() =>
    import("../components/HomePage/FLSWork/FLSWork")
)
const Hero = React.lazy(() => import("../components/HomePage/Hero/Hero"))
const NewWork = React.lazy(() =>
    import("../components/HomePage/NewWork/NewWork")
)
const RatingText = React.lazy(() =>
    import("../components/HomePage/RatingText/RatingText")
)
const BlogCards = React.lazy(() =>
    import("../components/LatestBlogs/LatestBlogs")
)

function Home({ location }) {
    const heroData = {
        title: "Forward-thinking digital solutions"
    }
    const context = {
        pageName: "SD | Home",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <Suspense
                fallback={
                    <div className="container grid min-h-screen place-items-center"></div>
                }
            >
                <>
                    <Hero props={heroData} />
                    <RatingText />
                    <FLSWork />
                    <NewWork />
                    <BlogCards />
                </>
            </Suspense>
        </Layout>
    )
}
export default Home
export const Head = () => (
    <SEO
        title={"Saigon Digital - Forward-thinking digital solutions"}
        description={
            "Solving digital challenges with forward-thinking, user-centric and bespoke solutions."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
